<template>
    <div v-if="survey" class="container-lg mt-3 mt-md-5 mb-3 mb-md-5">

        <Alerts :_error="error" :_success="success"></Alerts>

        <div class="preview-title">
            <h1>{{ survey.title }}</h1>
        </div>

        <!-- Form settings -->
        <div class="preview">

            <!-- General settings -->
            <div class="row settings-section">
                <div class="col-lg-5 settings-sidebar">
                    <h4>General Settings</h4>
                </div>
                <div class="col-lg-7 settings-content">
                    <div class="mb-3">
                        <label class="form-label" for="survey_category">Survey Categories</label>
                        <multiselect
                            class="mb-2"
                            v-model="selected_categories"
                            :options="categories"
                            :disabled="wait"
                            :multiple="true"
                            track-by="id"
                            :preserve-search="true"
                            :hide-selected="true"
                            placeholder="Select categories" label="name" @input="setCategoryIds">
                        </multiselect>
                        <div class="form-text">Select at least 1 category that best describes your survey.</div>
                    </div>

                    <div class="mb-3">
                        <label class="form-label" for="survey_status">Survey Status</label>
                        <select
                            class="form-select"
                            name="survey_status"
                            id="survey_status" v-model="survey.active">
                            <option value="1">Active</option>
                            <option value="0">Inactive</option>
                        </select>
                        <div class="form-text">Choose whether to activate or deactivate your survey.</div>
                    </div>

                    <div class="mb-3">
                        <div class="form-check form-switch">
                            <input
                                class="form-check-input"
                                type="checkbox"
                                id="multiple_responses"
                                v-model="survey.multi_response"
                            />
                            <label class="form-check-label" for="multiple_responses">Allow multiple responses</label>
                        </div>
                        <div class="form-text">Allow respondents to fill this survey more than once.</div>
                    </div>

                    <div class="mb-3">
                        <div class="form-check form-switch" id="survey_description">
                            <input
                                class="form-check-input"
                                type="checkbox"
                                id="form_description"
                                v-model="survey.show_description"
                            />
                            <label class="form-check-label" for="form_description">Show start and end messages</label>
                        </div>
                        <div class="form-text">Show the start and end messages.</div>
                    </div>

                    <div class="mb-3">
                        <div class="form-check form-switch">
                            <input
                                class="form-check-input"
                                type="checkbox"
                                id="collect_geo"
                                v-model="survey.collect_geo"
                            />
                            <label class="form-check-label" for="collect_geo"
                            >Collect GPS information</label
                            >
                        </div>
                        <div class="form-text">Allow users to submit their GPS location when responding to this
                            survey.
                        </div>
                    </div>
                </div>
            </div>
            <!-- End general settings -->

            <!-- Visibility settings -->
            <div class="row settings-section">
                <div class="col-lg-5 settings-sidebar">
                    <h4>Visibility Settings</h4>
                </div>
                <div class="col-lg-7 settings-content">
                    <div class="mb-3">
                        <label for="survey_url" class="form-label">Survey URL</label>
                        <div class="input-group">
                            <input
                                class="form-control"
                                id="survey_url"
                                :value="survey.publicUrl"
                                name="survey_url"
                                type="text"
                                disabled
                                aria-describedby="survey_url_prefix"
                            />
                        </div>
                        <p class="form-text">This is the public URL for your survey.</p>
                    </div>

                    <div class="mb-3">
                        <div class="checkbox-group">
                            <div class="form-check">
                                <input
                                    class="form-check-input"
                                    type="radio"
                                    v-model="survey.visibility"
                                    name="survey_visibility"
                                    id="survey_visible"
                                    checked="checked"
                                    value="public"
                                />
                                <label class="form-check-label" for="survey_visibility">Public</label>
                            </div>
                            <p class="form-text">Your survey will be publicly visible and searchable in the explore
                                page.
                            </p>
                        </div>

                        <div class="checkbox-group">
                            <div class="form-check">
                                <input
                                    class="form-check-input"
                                    type="radio"
                                    v-model="survey.visibility"
                                    name="survey_visibility"
                                    id="survey_restricted"
                                    value="restricted"
                                />
                                <label class="form-check-label">Restricted</label>
                            </div>
                            <p class="form-text"> Your survey will <strong>not</strong> be publicly visible. Only people
                                with the URL can view.
                            </p>
                        </div>

                        <div class="checkbox-group">
                            <div class="form-check">
                                <input
                                    class="form-check-input"
                                    type="radio"
                                    value="limited"
                                    v-model="survey.visibility"
                                    name="survey_visibility"
                                    id="survey_limited"
                                />
                                <label class="form-check-label" for="survey_visibility"
                                >Limited</label
                                >
                            </div>
                            <p class="form-text">Only selected users can view this form.</p>

                            <p class="font-weight-semibold mb-2" v-if="survey.users.length !== 0">Shared Users
                                ({{ survey.users.length }})</p>
                            <p class="text-muted small" v-else>No users selected</p>

                            <div class="mb-3 mt-3">
                                <label>Invite the following User</label><br>
                                <label class="text-muted">You can invite new users with their email address to join the survey</label>
                                <textarea class="form-control mt-1" rows="3" v-model="survey.invites" placeholder="E-Mails (comma separated)"></textarea>
                            </div>

                            <b-table-simple
                                responsive
                                stacked="md"
                                striped
                                class="shared-users">
                                <b-tbody>
                                    <b-tr v-for="u in survey.users" :key="u.id">
                                        <b-td>{{ u.name }}</b-td>
                                        <b-td>{{ u.email }}</b-td>
                                    </b-tr>
                                </b-tbody>
                            </b-table-simple>
                        </div>
                    </div>
                </div>
            </div>
            <!-- End visibility settings -->

            <!-- Welcome message -->
            <div class="row settings-section">
                <div class="col-lg-5 settings-sidebar">
                    <h4>Welcome Message</h4>
                </div>


                <div class="col-lg-7 settings-content">
                    <div class="my-3">
                        <div v-if="!survey.show_description" class="border border-danger p-3 rounded-3 text-danger">
                            Show start and end message settings has been disabled.<br>
                            Pleas <a href="#survey_description">click here</a> to enable
                        </div>
                    </div>

                    <div class="mb-2">
                        <div class="row">
                            <div class="col">
                                <label class="form-label">Welcome Message</label>
                                <textarea
                                    class="form-control"
                                    rows="3"
                                    :disabled="!survey.show_description"
                                    placeholder="Enter a brief introduction message that will welcome new respondents to your survey."
                                    v-model="survey.start_message" required>
                                </textarea>
                            </div>
                        </div>
                    </div>
                    <p class="form-text mb-0">Your respondents will be able to see this message before filling
                        survey.</p>
                </div>
            </div>

            <!-- End message -->
            <div class="row settings-section">
                <div class="col-lg-5 settings-sidebar">
                    <h4>End Message</h4>
                </div>
                <div class="col-lg-7 settings-content">
                    <div class="mb-2">
                        <div class="row">
                            <div class="col">
                                <label class="form-label">End Message</label>
                                <textarea
                                    class="form-control"
                                    rows="3"
                                    :disabled="!survey.show_description"
                                    placeholder="Enter a brief end message that will be displayed at the end of your survey"
                                    v-model="survey.end_message" required>
                                </textarea>
                            </div>
                        </div>
                    </div>
                    <p class="form-text mb-0">Your respondents will be able to see this message after successfully
                        responding to your survey.</p>
                </div>
            </div>

            <!--Danger Settings-->
            <div class="row settings-section">
                <div class="col-lg-5 settings-sidebar">
                    <h4>Response Settings</h4>
                </div>
                <div class="col-lg-7 settings-content">

                    <div class="mb-3">
                        <label class="form-label" for="survey_responses">Manual Approval</label>
                        <div class="mb-3">
                            <div class="form-check form-switch">
                                <input
                                    class="form-check-input"
                                    type="checkbox"
                                    v-model="survey.response_approve"
                                    id="response_approve"
                                    checked="checked"
                                    value="approve"
                                />
                                <label class="form-check-label" for="form_description">Manually approve
                                    responses</label>
                            </div>
                            <div class="form-text">Enable this setting if you would like to manually approve your
                                responses.
                            </div>
                        </div>

                        <label class="form-label" for="form_description">Manual Actions</label>
                        <div class="checkbox-group">
                            <div class="form-check">
                                <input
                                    class="form-check-input"
                                    type="checkbox"
                                    v-model="survey.response_flag"
                                    id="response_flag"
                                    checked="checked"
                                    value="flag"
                                />
                                <label class="form-check-label" for="response_flag">Flag</label>
                            </div>
                            <p class="form-text">Flagged responses will still appear in reports and can be exported.</p>
                        </div>

                        <div class="checkbox-group">
                            <div class="form-check">
                                <input
                                    class="form-check-input"
                                    type="checkbox"
                                    v-model="survey.response_decline"
                                    id="response_decline"
                                    value="decline"
                                />
                                <label class="form-check-label" for="response_decline">Decline</label>
                            </div>
                            <p class="form-text">Declined responses can still be exported but will be excluded from
                                reports.</p>
                        </div>
                    </div>
                  <div>
                    <DeleteResponse />
                  </div>
                </div>
            </div>
            <div class="row settings-section">
                <div class="col-lg-5 settings-sidebar">
                    <h4>Danger Zone</h4>
                </div>
                <div class="col-lg-7 settings-content">

                    <div class="mb-3">
                        <h6 class="mb-3">Delete Responses</h6>
                            <div class="check-group">
                                <p>If you would like to permanently delete your Survey Responses, click <strong>"Delete My Responses"</strong>. This will erase all your survey responses</p>
                                <button class="custom-btn custom-btn-danger" v-b-modal.delete-response-modal>Delete Responses</button>
                            </div>  
                    </div>
                </div>
            </div>
            <button @click.prevent="updateSurvey()" class="custom-btn custom-btn-success">Save changes</button>
        </div>
        <!-- End survey settings -->
        <div>
            <DeleteResponse :survey_code="code"/>
        </div>
    </div>
    <!-- End container-lg -->
</template>

<script>

import Alerts from "../../Helpers/Alerts";
import Multiselect from 'vue-multiselect'
import DeleteResponse from "@/components/Views/Profile/DeleteResponse";
export default {
    name: "SurveySettings",
    components: {Alerts, Multiselect, DeleteResponse},
    props: ['code'],
    data() {
        return {
            survey: null,

            categories: [],
            selected_categories: [],

            users: [],
            selected_users: [],
        }
    },
    watch: {
        search_user_query(a) {
            if (a.length >= 2) {
                this.submitUserSearch()
            } else {
                this.user_results = []
            }
        }
    },
    mounted() {
        this.$http.get("/api/profile/survey/" + this.code).then(response => {
            this.survey = response.data.data
            this.getCategories()
            this.loadUsers()

            this.selected_categories = this.survey.categories
            this.setCategoryIds(this.selected_categories)

            this.selected_users = this.survey.users
            this.setUserIds(this.selected_users)
        }).catch(error => {
            this.showError(error)
        });
    },
    methods: {
        setCategoryIds(categories) {
            let ids = [];
            categories.forEach((i) => {
                ids.push(i.id);
            });
            this.survey.category_ids = ids;
        },
        setUserIds(user_results) {
            let ids = [];
            user_results.forEach((i) => {
                ids.push(i.id);
            });
            this.survey.user_ids = ids;
        },
        submitUserSearch() {
            this.$http.get('/api/search/users?search=' + this.search_user_query).then(response => {
                this.user_results = response.data.data
                this.selected_users = (this.user_results)
                //
                // response.data.data.forEach(a => {
                //     let index = this.selected_users.indexOf(x => x.id === a.id)
                //     index === -1 ? this.selected_users.push(a) : this.selected_users.slice(index, 1)
                //     index === -1 ? this.user_results.push(a) : this.selected_users.slice(index, 1)
                // })
                this.setUserIds(this.selected_users)
            }).catch(error => {
                this.showError(error)
            })
        },
        updateSurvey() {
            this.$http.post('/api/profile/survey/create', this.survey).then(res => {
                //this.success = "Survey settings updated successfully."
                this.$toaster.success('Survey settings updated')
                this.survey = res.data.data
                window.scroll(0, 0)
                console.log(this.survey)
            })
        },
        loadUsers() {
            this.$http.get('/api/users').then(res => {
                this.users = res.data.data
            })
        },
        getCategories() {
            this.$http.get('/api/categories').then(res => {
                this.categories = res.data.data
            })
        }
    }

}
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style scoped>
</style>