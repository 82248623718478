<template>

    <b-modal class="modal" id="delete-response-modal" hide-footer centered>

        <!-- Alert Messages -->
        <Alerts :_error="error" :_success="success"></Alerts>

        <!-- Modal title -->
        <template #modal-title>Confirm Deletion</template>
        <p>Deleting your responses will erase all your response data from our database. This action cannot be
            undone.</p>
        <p>To confirm this, type your account password in the input box below.</p>
        <div class="mb-4">
            <input v-model="password" class="form-control" type="password" required :disabled="wait"/>
        </div>
        <button class="custom-btn custom-btn-secondary me-2" @click="$bvModal.hide('delete-response-modal')"
                :disabled="wait || !password">
            Cancel
        </button>
        <button class="custom-btn custom-btn-danger" @click="deleteResponse()" :disabled="wait">
            <template v-if="wait">Please wait...</template>
            <template v-else>Delete My Responses</template>
        </button>
        <div>
        </div>

    </b-modal><!-- End modal -->

</template>

<script>
import Alerts from "../Helpers/Alerts";

export default {
    name: "DeleteResponse",
    props: ['survey_code'],
    components: {Alerts},

    data() {
        return {
            password: ''
        }
    },
    methods: {
        deleteResponse() {
            this.wait = true
            this.clear()
            this.$http.post(`api/profile/survey/${this.survey_code}/delete_responses`, {password: this.password}).then(() => {
                let msg = "Survey responses deleted successfully!"
                this.success = msg
                this.$toaster.success(msg)
                setTimeout(() => this.$router.push(`/profile/surveys/view/${this.survey_code}/questionnaire`), 500)
            }).catch(error => {
                this.showError(error)
            }).then(() => {
                this.wait = false
            })
        },
    }
}
</script>

<style scoped>
</style>